import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Title from '../components/Title';

const PrivacyPolicy = () => (
  <Layout title="Privacy Policy">
    <div className="c-content-box c-size-md">
      <div className="container">
        <Title
          main="Privacy Policy"
          helper="This privacy policy was last modified and published on January 01, 2019."
        />
        <div className="row">
          <div className="col-md-12 c-content-feature-15">
            <div className="c-feature-15-container c-bg-white c-bg-opacity-5" style={{ marginTop: 0 }}>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                What personal data we collect and why we collect it
              </h2>
              <p className="c-feature-15-desc">
                Calf Hey Group ("we" or "us") knows that you care how information about you is used and shared. This
                Privacy Policy explains what information of yours will be collected when you use our website at{' '}
                <Link to="/">
                  <strong>https://calf-hey.com</strong>
                </Link>
                , how the information will be used, and how you can control the collection, correction and/or deletion
                of information. We will not use or share your information with anyone except as described in this
                Privacy Policy.
              </p>
              <h3 className="c-font-bold c-font-uppercase c-margin-t-30">User-Provided Information</h3>
              <p>
                You provide us information about yourself, such as your name, e-mail address, and other personally
                identifiable information (all referred to as "Personal Information") if you register for a User account
                with us.
              </p>
              <p>Currently, the Website does not have areas or features that demand a user to explicitly register.</p>
              <p>Currently, the Website does not collect any comments or reviews.</p>
              <p>
                If you correspond with us by email, we may retain the content of your email messages, your email address
                and our responses. We may also retain any messages you send through the website.
              </p>
              <h3 className="c-font-bold c-font-uppercase c-margin-t-30">Contact forms</h3>
              <p>
                The Website includes several contact forms that can be used to send a message to us directly or to make
                a request.
              </p>
              <p>
                Messages received via the contact forms are not stored directly on the Website, but they are received on
                our end as regular emails. As such, we may retain the content of your message, the provided email
                address and our response for future reference.
              </p>
              <h3 className="c-font-bold c-font-uppercase c-margin-t-30">Cookies</h3>
              <p>
                As is common practice with almost all professional websites, this site uses cookies, which are tiny
                files that are downloaded to your computer, to improve your experience.
              </p>
              <p className="c-margin-t-20">
                <strong>How We Use Cookies</strong>
                <br />
                We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no
                industry standard options for disabling cookies without completely disabling the functionality and
                features they add to this site. It is recommended that you leave on all cookies if you are not sure
                whether you need them or not in case they are used to provide a service that you use.
              </p>
              <p className="c-margin-t-20">
                <strong>Disabling Cookies</strong>
                <br />
                You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help
                for how to do this). Be aware that disabling cookies will affect the functionality of this and many
                other websites that you visit. Disabling cookies will usually result in also disabling certain
                functionality and features of this site. Therefore it is recommended that you do not disable
                cookies.
              </p>
              <p className="c-margin-t-20">
                <strong>The Cookies We Set</strong>
                <br />
                In order to provide you with a great experience on this site we provide the functionality to set your
                preferences for how this site runs when you use it. In order to remember your preferences we need to set
                cookies so that this information can be called whenever you interact with a page that is affected by your
                preferences.
              </p>
              <p className="c-margin-t-20">
                <strong>Third Party Cookies</strong>
                <br />
                In some special cases we may also use cookies provided by trusted third parties. Such special cases,
                before they are initiated, shall be disclosed in this Privacy Policy.
              </p>
              <h3 className="c-font-bold c-font-uppercase c-margin-t-30">Embedded content from other websites</h3>
              <p>
                Articles or product pages on this site may include embedded content (e.g. videos, images, articles,
                etc.). Embedded content from other websites behave in the exact same way as if the visitor has visited
                the other website.
              </p>
              <p>
                These websites may collect data about you, use cookies, embed additional third-party tracking, and
                monitor your interaction with that embedded content, including tracing your interaction with the
                embedded content if you have an account and are logged in to that website.
              </p>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                How long we retain your data
              </h2>
              <p className="c-feature-15-desc">
                For users that register on our website (if any), we also store the personal information they provide in
                their user profile. All users can see, edit, or delete their personal information at any time (except
                they cannot change their username). Website administrators can also see and edit that information.
              </p>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                What rights you have over your data
              </h2>
              <p className="c-feature-15-desc">
                If you have an account on this site, you can request to receive an exported file of the personal data we
                hold about you, including any data you have provided to us. You can also request that we erase any
                personal data we hold about you. This does not include any data we are obliged to keep for
                administrative, legal, or security purposes.
              </p>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                Your Choices About Your Information
              </h2>
              <h3 className="c-font-bold c-font-uppercase c-margin-t-30">All Users</h3>
              <ul>
                <li>
                  <strong>Decline to Submit Information.</strong> You may, of course, decline to submit Personal
                  Information through the Service, in which case Calf Hey Group may not be able to provide certain
                  services to you.
                </li>
                <li>
                  <strong>Update Account Information.</strong> You may update or correct your account information and
                  email preferences at any time by logging in to your account settings page. You can also exercise any
                  of your rights by contacting us directly at{' '}
                  <a href="mailto:sales@calf-hey.com" target="_blank" rel="noopener noreferrer">
                    <strong>sales@calf-hey.com</strong>
                  </a>
                </li>
                <li>
                  <strong>Blocking cookies.</strong> Certain browsers may be configured to notify you when you receive
                  cookies, or allow you to restrict or disable certain cookies. If you choose to disable cookies,
                  however, that could affect certain features of the Website that use cookies to enhance their
                  functionality.
                </li>
              </ul>
              <h3 className="c-font-bold c-font-uppercase c-margin-t-30">EEA Residents</h3>
              <p>
                If you are located in the EEA, or if otherwise required by applicable law, you also have the following
                rights in relation to your information:
              </p>
              <ul>
                <li>
                  Inspection and access: you can request from us a summary and a copy of any Personal Information we
                  hold about you;
                </li>
                <li>
                  Correction/addition/removal: where you believe your information is inaccurate or incomplete, you are
                  entitled to request us to correct, amend or delete it;
                </li>
                <li>
                  Portability: you may request that we send you any Personal Information that you have provided to us or
                  ask that we send this information to a third party;
                </li>
                <li>
                  Objection: you may object to us using your Personal Information where we use it based on our
                  legitimate interests to do so;
                </li>
                <li>
                  Restriction: you may request that we restrict the processing of your information where you believe it
                  is inaccurate, our processing is unlawful, you believe we no longer need the information or if you
                  have objected to our use of it;
                </li>
              </ul>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                Children’s Privacy
              </h2>
              <p className="c-feature-15-desc">
                Protecting the privacy of young children is especially important. For that reason, we do not knowingly
                collect or solicit Personal Information from anyone under the age of 13 or knowingly allow such persons
                to register. If you are under 13, please do not send any information about yourself to us, including
                your name, address, telephone number, or email address. No one under age 13 is allowed to provide any
                Personal Information to or on Calf Hey Group. In the event that we learn that we have collected Personal
                Information from a child under age 13 without verification of parental consent, we will delete that
                information as quickly as possible. If you believe that we might have any information from or about a
                child under 13, please contact us at <strong>sales@calf-hey.com</strong>
              </p>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                How We Protect Your Information
              </h2>
              <p className="c-feature-15-desc">
                We care about the integrity and security of your Personal Information and implement appropriate
                technical and organisation security measures to safeguard your Personal Information. We cannot, however,
                ensure or warrant the security of any information you transmit to Calf Hey Group or guarantee that your
                information on the Website may not be accessed, disclosed, altered, or destroyed by breach of any of our
                physical, technical, or managerial safeguards. We are not responsible for the functionality or security
                measures of any third party.
                <br />
                <br />
                To protect your privacy and security, we take reasonable steps (such as requesting a unique password) to
                verify your identity before granting you access to your account. You are responsible for maintaining the
                secrecy of your unique password and account information, and for controlling access to your email
                communications from Calf Hey Group, at all times.
              </p>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                Compromise of Personal Information
              </h2>
              <p className="c-feature-15-desc">
                In the event that Personal Information is compromised as a result of a breach of security, we will
                promptly notify those persons whose Personal Information has been compromised, in accordance with the
                notification procedures set forth in this Privacy Policy, or as otherwise required by applicable law.
              </p>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                Links to Other Web Sites
              </h2>
              <p className="c-feature-15-desc">
                We are not responsible for the practices employed by websites linked to or from the Website, nor the
                information or content contained therein. Please remember that when you use a link to go from the
                Website to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on
                any other website, including those that have a link on our website, is subject to that website’s own
                rules and policies. Please read over those rules and policies before proceeding.
              </p>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                Notification Procedures
              </h2>
              <p className="c-feature-15-desc">
                It is our policy to provide notifications, whether such notifications are required by law or are for
                marketing or other business related purposes, to you via email notice, written or hard copy notice, or
                through conspicuous posting of such notice on the Website, as determined by us in our sole discretion.
                We reserve the right to determine the form and means of providing notifications to you, provided that
                you may opt out of certain means of notification as described in this Privacy Policy.
              </p>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                Changes to Our Privacy Policy
              </h2>
              <p className="c-feature-15-desc">
                We may update its Privacy Policy from time to time, and so you should review this Policy periodically.
                When we change the policy in a material way, we will update the ‘last modified’ date at the top of this
                Privacy Policy. Changes to this Privacy Policy are effective when they are posted on this page.
              </p>
              <h2 className="c-feature-15-title c-font-bold c-font-uppercase c-theme-border c-margin-t-40">
                Contact Us
              </h2>
              <p className="c-feature-15-desc">
                If you have any questions about this Privacy Policy, the practices of this Site, or your dealings with
                this website, please contact us at{' '}
                <a href="mailto:sales@calf-hey.com" target="_blank" rel="noopener noreferrer">
                  <strong>sales@calf-hey.com</strong>
                </a>
                , or send mail to:
              </p>

              <p className="c-font-bold">
                CALF HEY GROUP
                <br />
                Victoria House, Victoria Street
                <br />
                Lockwood, Huddersfield
                <br />
                West Yorkshire
                <br />
                HD1 3RG
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default PrivacyPolicy;
